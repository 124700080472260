export const lateMorningHours = {
    0: 2400, // 12am
    15: 2415, // 12:15am
    30: 2450, // 12:30am
    45: 2445, // 12:45am
    100: 2500, // 1am
    115: 2515, // 1:15am
    130: 2530, // 1:30am
    145: 2545, // 1:45am
    200: 2600, // 2am
    215: 2615, // 2:15am
    230: 2630, // 2:30am
    245: 2645, // 2:45am
    300: 2700, // 3am
    315: 2715, // 3:15am
    330: 2730, // 3:30am
    345: 2745, // 3:45am
    400: 2800, // 4am
}
