import * as structuredDataSchemas from '../templates/structuredData'
import { SchemaType, IDynamicData } from '../../types'
import { basePath } from './basePath'

export const getJsonLd = (schemaType: SchemaType, dynamicData: IDynamicData) => {
    let structuredData = structuredDataSchemas[schemaType] || structuredDataSchemas.defaultPage
    const urlBase = basePath()
    if (typeof dynamicData === 'object') {
        let structuredDataString = JSON.stringify(structuredData)
        structuredDataString = structuredDataString?.replace(/\{title\}/g, escapeQuotes(dynamicData?.title))
        structuredDataString = structuredDataString?.replace(
            /\{canonical\}/g,
            `${urlBase}/${dynamicData?.canonical}`
        )
        structuredDataString = structuredDataString?.replace(/\{image\}/g, dynamicData?.image)
        structuredDataString = structuredDataString?.replace(
            /\{description\}/g,
            dynamicData?.description || ''
        )
        structuredDataString = structuredDataString?.replace(
            /\{brand\.title\}/g,
            dynamicData?.location?.brand?.title
        )
        structuredDataString = structuredDataString?.replace(
            /\{brand\.link\}/g,
            `${urlBase}/brewer/${dynamicData?.location?.brand?.slug || dynamicData?.product?.brand?.slug
            }`
        )
        structuredDataString = structuredDataString?.replace(
            /\{address\.street\}/g,
            dynamicData?.location?.address?.street
        )
        structuredDataString = structuredDataString?.replace(
            /\{address\.city\}/g,
            dynamicData?.location?.address?.city
        )
        structuredDataString = structuredDataString?.replace(
            /\{address\.state\}/g,
            dynamicData?.location?.address?.state
        )
        structuredDataString = structuredDataString?.replace(
            /\{address\.postalCode\}/g,
            dynamicData?.location?.address?.postalCode
        )
        structuredDataString = structuredDataString?.replace(
            /\{address\.geo\.lat\}/g,
            dynamicData?.location?.address?.geo?.lat?.toString()
        )
        structuredDataString = structuredDataString?.replace(
            /\{address\.geo.lon\}/g,
            dynamicData?.location?.address?.geo?.lon?.toString()
        )
        structuredDataString = structuredDataString?.replace(
            /\{address\.country\}/g,
            dynamicData?.location?.address?.country
        )
        structuredDataString = structuredDataString?.replace(
            /\{contact\.phone\}/g,
            dynamicData?.location?.contact?.phone?.toString()
        )
        structuredData = JSON.parse(structuredDataString) || ''
    }

    return structuredData
}

function escapeQuotes(str: string | undefined): string {
    return str ? str.replace(/"/g, '\\"') : '';
}