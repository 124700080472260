import React from 'react'
import { useRouter } from 'next/router'
import { basePath, getOpenGraphData } from '../utils/helpers'
import Head from 'next/head'
import { pages } from '../utils/constants'
import { IStructuredDataProps } from '../types'

const SEOTags = ({ schemaType, dynamicData }: IStructuredDataProps) => {
    const openGraphData = getOpenGraphData(schemaType, dynamicData)

    const page = {
        ...pages[schemaType],
        ...dynamicData,
    }
    const baseUrl = basePath()

    return (
        <>
            {schemaType && openGraphData && (
                <Head>
                    <title key='title' data-blx-seo>
                        {page?.title}
                    </title>
                    <meta
                        name='description'
                        key='meta-description'
                        content={page?.description}
                        data-blx-seo
                    />
                    <meta
                        name='robots'
                        content={page?.robots || 'index,follow'}
                        key='robots'
                        data-blx-seo
                    />
                    <link
                        rel='canonical'
                        href={`${baseUrl}/${page?.canonical}`}
                        key='canonical'
                        data-blx-seo
                    />
                    {Object.entries(openGraphData).map((tag: any) => {
                        return (
                            <meta
                                property={(tag[0].includes('og:') && tag[0]) || null}
                                name={(tag[0].includes('twitter:') && tag[0]) || null}
                                content={tag[1]}
                                key={tag[0]}
                                data-blx-seo
                            />
                        )
                    })}
                </Head>
            )}
        </>
    )
}

export default SEOTags
