import { Hours } from 'src/types/schema/graphql'
import { isEqual } from 'lodash'

const hoursMatch = (a: Hours, b: Hours): boolean => {
    if (a?.day !== b?.day) {
        return isEqual(a?.hours, b?.hours)
    }
    return false
}

const statusMatch = (a: Hours, b: Hours): boolean => {
    return a?.isOpen === b?.isOpen
}

const prevDay = (idx: number, arr: Hours[]): null | Hours => {
    return idx === 0 ? null : arr[idx - 1]
}

export const sortSameBusinessHours = (days: Hours[]): Hours[][] => {
    let runningArr = []
    const arr: Hours[][] = []

    days?.forEach((day, i) => {
        const isMatch = hoursMatch(day, prevDay(i, days))
        const isClosedMatch = statusMatch(day, prevDay(i, days))
        const runningIsEmpty = runningArr.length === 0

        // If running array is empty and we're on the first item of the main days Array
        if (runningIsEmpty && i === 0) {
            runningArr.push(day)
            // If running array is not empty and we're past the first item of the array but not on the last item of the array and there is no match
        } else if ((!isMatch && i > 0 && i < days.length - 1) || !isClosedMatch) {
            arr.push(runningArr)
            runningArr = []
            runningArr.push(day)
        }
        // If running array is empty and we're past the first item and there is a match
        if (!runningIsEmpty && i > 0 && isMatch && isClosedMatch) {
            runningArr.push(day)
        }
        // If we're on the last item of the array and there is no match
        if (i === days.length - 1) {
            arr.push(runningArr)
            if (day.isOpen && !isMatch && !runningArr.includes(day)) {
                arr.push([day])
            }
        }
    })

    return arr
}
