export const basePath = () => {
    const paths = {
        production: 'https://www.brewerydb.com',
        development: 'https://dev.brewerydb.com',
        staging: 'https://staging.brewerydb.com',
        local: 'http://localhost:3000',
    }

    return paths[process.env.NODE_ENV || 'production']
}
