import { IOpenGraphImage } from '../types/'

export const dynamicSchemas = ['location', 'product', 'brand']
export const siteTitle = 'BreweryDB | Your Next Brewery Adventure Awaits!'
export const siteDescription =
    'Discover breweries and brews near or far with BreweryDB. Your Next Brewery Adventure Awaits!'

export const sitemapConfig = {
    pageSize: 250,
    pageLimit: 25,
}

export const shareImage: IOpenGraphImage = {
    url: 'https://www.brewerydb.com/assets/brewerydb-preview-01.jpg',
    width: 1200,
    height: 630,
    type: 'image/jpeg',
}

export const downloadShareImage: IOpenGraphImage = {
    url: 'https://www.brewerydb.com/assets/brewerydb-download-preview-01.jpg',
    width: 1200,
    height: 630,
    type: 'image/jpeg',
}

export interface ISeoPageConfig {
    title: string
    description: string
    image?: IOpenGraphImage
    robots?: string
    canonical: string
}

export const pages = {
    home: {
        title: 'BreweryDB | Your Next Brewery Adventure Awaits!',
        description:
            'Discover breweries and brews near or far with BreweryDB. Your Next Brewery Adventure Awaits!',
        image: shareImage,
        robots: 'index,follow',
        canonical: '',
    },
    map: {
        title: 'Brewery Map | BreweryDB',
        description: 'Explore, discover and plan the perfect brewery experience with BreweryRoutes',
        image: {
            ...shareImage,
            url: 'https://www.brewerydb.com/assets/brewerydb-preview-map-01.jpg',
        },
        robots: 'index,follow',
        canonical: 'map',
    },
    brewknowledge: {
        title: 'BrewKnowledge | BreweryDB',
        description:
            'Search our database of over 100,000 brews to find something new or expand your knowledge.',
        image: shareImage,
        robots: 'index,follow',
        canonical: 'brewknowledge',
    },
    brand: {
        title: '{title} | BreweryDB',
        description: '{description}',
        image: {
            ...shareImage,
            width: 300,
            height: 300,
        },
        robots: 'index,follow',
        canonical: '',
    },
    download: {
        title: 'Download the BreweryDB Mobile App | BreweryDB',
        description:
            'Use the new BreweryDB mobile app to discover new brews, find places to try them, optimize your BreweryRoute, and save it all on a custom board that you curate.',
        image: downloadShareImage,
        robots: 'index,follow',
        canonical: 'download',
    },
    location: {
        title: '{title} - {brand.title} | BreweryDB',
        description: '{description}',
        image: {
            ...shareImage,
            width: 300,
            height: 300,
        },
        robots: 'index,follow',
        canonical: '',
    },
    product: {
        title: '{title} - {brand.title} | BreweryDB',
        description: '{description}',
        image: {
            ...shareImage,
            width: 300,
            height: 300,
        },
        robots: 'index,follow',
        canonical: '',
    },
}

export const daysOfWeekObject = {
    Sun: 'Sunday',
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday',
}
