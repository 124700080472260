import { IOpenGraphData } from '../../types'
import { downloadShareImage, pages, shareImage, siteDescription, siteTitle } from '../constants'

const globals: IOpenGraphData = {
    'og:locale': 'en_US',
    'og:type': 'website',
    'og:site_name': 'BreweryDB.com',
    'og:image': shareImage?.url,
    'og:image:width': shareImage?.width,
    'og:image:height': shareImage?.height,
    'og:image:type': shareImage?.type,
    'twitter:card': 'summary_large_image',
    'twitter:image': shareImage?.url,
    'twitter:site': '@brewerydb',
}

const home: IOpenGraphData = {
    ...globals,
    'og:title': siteTitle,
    'og:description': siteDescription,
    'twitter:title': siteTitle,
    'twitter:description': siteDescription,
}
const map: IOpenGraphData = {
    ...globals,
    'og:title': pages.map.title,
    'og:description': pages.map.description,
    'twitter:title': pages.map.title,
    'twitter:description': pages.map.description,
    'og:image': 'https://www.brewerydb.com/assets/brewerydb-preview-map-01.jpg',
    'twitter:image': 'https://www.brewerydb.com/assets/brewerydb-preview-map-01.jpg',
}
const brewknowledge: IOpenGraphData = {
    ...globals,
    'og:title': pages.brewknowledge.title,
    'og:description': pages.brewknowledge.description,
    'twitter:title': pages.brewknowledge.title,
    'twitter:description': pages.brewknowledge.description,
    'og:image': 'https://www.brewerydb.com/assets/brewerydb-preview-map-01.jpg',
    'twitter:image': 'https://www.brewerydb.com/assets/brewerydb-preview-map-01.jpg',
}
const download: IOpenGraphData = {
    ...globals,
    'og:title': pages.download.title,
    'og:description': pages.download.description,
    'twitter:title': pages.download.title,
    'twitter:description': pages.download.description,
    'og:image': downloadShareImage.url,
    'twitter:image': downloadShareImage.url,
}
const location: IOpenGraphData = {
    ...globals,
    'og:image:width': 300,
    'og:image:height': 300,
}

const defaultPage = {
    ...home,
}

export { globals, home, download, map, location, brewknowledge, defaultPage }
