import React from 'react'
import { useRouter } from 'next/router'
import { getJsonLd } from '../utils/helpers'
import Script from 'next/script'
import { IStructuredDataProps } from '../types'

const StructuredData = ({ schemaType, dynamicData }: IStructuredDataProps) => {
    const structuredData = JSON.stringify(getJsonLd(schemaType, dynamicData))
    return (
        <Script
            id='structured-data'
            key='structured-data'
            type='application/ld+json'
            strategy='beforeInteractive'
            dangerouslySetInnerHTML={{
                __html: structuredData,
            }}
        />
    )
}

export default StructuredData
