import { IDynamicData, SchemaType } from '../../types'
import * as openGraphTemplates from '../templates/openGraph'

export const getOpenGraphData = (schemaType: SchemaType, dynamicData: IDynamicData) => {
    const openGraphMeta = openGraphTemplates[schemaType] || openGraphTemplates.defaultPage
    if (dynamicData?.title) {
        openGraphMeta['og:title'] = dynamicData?.title
        openGraphMeta['twitter:title'] = dynamicData?.title
    }
    if (dynamicData?.description) {
        openGraphMeta['og:description'] = dynamicData?.description
        openGraphMeta['twitter:description'] = dynamicData?.description
    }
    if (dynamicData?.image) {
        openGraphMeta['og:image'] = dynamicData?.image
        openGraphMeta['twitter:image'] = dynamicData?.image
    }
    return openGraphMeta
}
