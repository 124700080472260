import { DateTime } from 'luxon'
import { Hours } from 'src/types/schema/graphql'
import { formatTime } from '../../helpers'
import { lateMorningHours } from '../constants/lateMorningHours'

interface IOpenStatus {
    isOpenNow: boolean
    message: string
}

export const getOpenStatus = (day: Hours): IOpenStatus => {
    const nowNumber = parseInt(DateTime.now().toFormat('HH:mm').replace(':', ''))
    const isOpenToday = day?.isOpen
    const statusOpenArr: boolean[] = []
    let nextOpen = null
    let nextClose = null
    let nextOpenIndex = null

    day.hours.forEach((hourBlock, i) => {
        const openNumber = parseInt(hourBlock[0].replace(':', ''))
        const closeNumber = lateMorningHours?.[parseInt(hourBlock[1].replace(':', ''))]
            ? lateMorningHours?.[parseInt(hourBlock[1].replace(':', ''))]
            : parseInt(hourBlock[1].replace(':', ''))

        const openTime = hourBlock[0]
        const closeTime = hourBlock[1]

        if (nowNumber >= openNumber && nowNumber < closeNumber) {
            statusOpenArr.push(true)
            nextClose = closeTime
        } else {
            statusOpenArr.push(false)
            nextOpen = openTime
            if (nowNumber < openNumber) nextOpenIndex = i - 1 < 0 ? 0 : i
        }
    })
    const nextHourBlockExists = day.hours?.[nextOpenIndex]?.[0]
    let message = statusOpenArr.includes(true)
        ? `Open until ${formatTime(nextClose)}`
        : nextHourBlockExists
        ? `Closed until ${formatTime(day.hours?.[nextOpenIndex]?.[0])}`
        : 'Closed'
    if (!isOpenToday) message = 'Closed Today'

    const openStatus = { isOpenNow: isOpenToday && statusOpenArr.includes(true), message }
    return openStatus
}
