const organization = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'BreweryDB',
    legalName: 'BreweryDB',
    url: 'http://www.brewerydb.com',
    logo: 'http://www.brewerydb.com/brewerydb-logo--no-tagline.svg',
    foundingDate: '2010',
    address: {
        '@type': 'PostalAddress',
        streetAddress: '6 E Washington St.',
        addressLocality: 'Indiannapolis',
        addressRegion: 'IA',
        postalCode: '46204',
        addressCountry: 'USA',
    },
    contactPoint: {
        '@type': 'ContactPoint',
        contactType: 'customer support',
        telephone: '[+317-296-7474]',
        email: 'contact@brewlogix.com',
    },
    sameAs: [
        'https://www.instagram.com/brewerydb',
        'https://www.facebook.com/brewlogix/',
        'https://twitter.com/brewerydb',
    ],
}

const global = {
    '@context': 'https://schema.org',
    '@graph': [
        {
            '@type': 'WebSite',
            '@id': 'https://www.brewerydb.com/#website',
            url: 'https://www.brewerydb.com/',
            name: 'brewerydb.com',
            description: 'Your Next Brewery Adventure Awaits…',
            potentialAction: [
                {
                    '@type': 'SearchAction',
                    target: {
                        '@type': 'EntryPoint',
                        urlTemplate:
                            'https://www.brewerydb.com/search?searchTerm={search_term_string}',
                    },
                    'query-input': 'required name=search_term_string',
                },
            ],
            inLanguage: 'en-US',
        },
        organization,
    ],
}

const breadCrumbList = {
    '@type': 'BreadcrumbList',
    itemListElement: [
        {
            '@type': 'ListItem',
            position: 1,
            name: 'Home',
            item: 'https://www.brewerydb.com/',
        },
    ],
}

const home = {
    ...global,
    '@graph': [
        ...global['@graph'],
        {
            '@type': 'ImageObject',
            inLanguage: 'en-US',
            '@id': 'https://www.brewerydb.com/#primaryimage',
            url: 'https://www.brewerydb.com/assets/brewerydb-preview-01.jpg',
            contentUrl: 'https://www.brewerydb.com/assets/brewerydb-preview-01.jpg',
            width: 1200,
            height: 630,
            caption: 'brewerydb.com',
        },
        {
            '@type': 'WebPage',
            '@id': 'https://www.brewerydb.com/#webpage',
            url: 'https://www.brewerydb.com/',
            name: 'Discover breweries and brews near or far | BreweryDB',
            isPartOf: { '@id': 'https://www.brewerydb.com/#website' },
            primaryImageOfPage: {
                '@id': 'https://www.brewerydb.com/#primaryimage',
            },
            datePublished: '2010-05-01T21:28:55+00:00',
            dateModified: '2022-10-04T16:41:15+00:00',
            description:
                'BreweryDB is an app for exploring the world of beer. Disvocery new breweries, brews and plan an adventure with BreweryRoutes! ',
            breadcrumb: { '@id': 'https://www.brewerydb.com/#breadcrumb' },
            inLanguage: 'en-US',
            potentialAction: [
                {
                    '@type': 'ReadAction',
                    target: ['https://www.brewerydb.com/'],
                },
            ],
        },
        {
            '@type': 'BreadcrumbList',
            '@id': 'https://www.brewerydb.com/#breadcrumb',
            itemListElement: [{ '@type': 'ListItem', position: 1, name: 'Home' }],
        },
    ],
}
const download = {
    ...global,
    '@graph': [
        ...global['@graph'],
        {
            '@type': 'ImageObject',
            inLanguage: 'en-US',
            '@id': 'https://www.brewerydb.com/download/#primaryimage',
            url: 'https://www.brewerydb.com/assets/brewerydb-download-preview-01.jpg',
            contentUrl: 'https://www.brewerydb.com/assets/brewerydb-download-preview-01.jpg',
            width: 1200,
            height: 630,
            caption: 'BreweryDB Mobile App',
        },
        {
            '@type': 'WebPage',
            '@id': 'https://www.brewerydb.com/#webpage',
            url: 'https://www.brewerydb.com/download',
            name: 'Find Your New Go-To Spot with BreweryDB | BreweryDB',
            isPartOf: { '@id': 'https://www.brewerydb.com/#website' },
            primaryImageOfPage: {
                '@id': 'https://www.brewerydb.com/download/#primaryimage',
            },
            datePublished: '2023-09-15T21:28:55+00:00',
            dateModified: '2023-09-17T16:41:15+00:00',
            description:
                'Use the BreweryDB mobile app to discover new brews, find places to try them, optimize your BreweryRoute, and save it all on a custom board that you curate.',
            breadcrumb: { '@id': 'https://www.brewerydb.com/#breadcrumb' },
            inLanguage: 'en-US',
            potentialAction: [
                {
                    '@type': 'ReadAction',
                    target: ['https://www.brewerydb.com/download'],
                },
            ],
        },
        {
            '@type': 'BreadcrumbList',
            '@id': 'https://www.brewerydb.com/#breadcrumb',
            itemListElement: [
                { '@type': 'ListItem', position: 1, name: 'Home' },
                { '@type': 'ListItem', position: 2, name: 'Download' },
            ],
        },
    ],
}

const map = {
    ...global,
    '@graph': [
        ...global['@graph'],
        {
            '@type': 'ImageObject',
            inLanguage: 'en-US',
            '@id': 'https://www.brewerydb.com/map#primaryimage',
            url: 'https://www.brewerydb.com/assets/brewerydb-preview-map-01.jpg',
            contentUrl: 'https://www.brewerydb.com/assets/brewerydb-preview-map-01.jpg',
            width: 1200,
            height: 630,
            caption: 'BreweryDB Map',
        },
        {
            '@type': 'WebPage',
            '@id': 'https://www.brewerydb.com/map#webpage',
            url: 'https://www.brewerydb.com/map',
            name: '',
            isPartOf: {
                '@id': 'https://www.brewerydb.com/#website',
            },
            primaryImageOfPage: {
                '@id': 'https://www.brewerydb.com/map#primaryimage',
            },
            datePublished: '2011-11-19T19:00:00+00:00',
            dateModified: '2022-10-11T16:22:03+00:00',
            description: '',
            breadcrumb: {
                '@id': 'https://www.brewerydb.com/map#breadcrumb',
            },
            inLanguage: 'en-US',
            potentialAction: [
                {
                    '@type': 'ReadAction',
                    target: ['https://www.brewerydb.com/map'],
                },
            ],
        },
        {
            ...breadCrumbList,
            '@id': 'https://www.brewerydb.com/map#breadcrumb',
            itemListElement: [
                ...breadCrumbList.itemListElement,
                {
                    '@type': 'ListItem',
                    position: 2,
                    name: 'Map',
                    item: 'https://www.brewerydb.com/map',
                },
            ],
        },
    ],
}
const location = {
    ...global,
    '@graph': [
        ...global['@graph'],
        {
            '@type': 'ImageObject',
            inLanguage: 'en-US',
            '@id': '{image}#primaryimage',
            url: '{image}',
            contentUrl: '{image}',
            width: 300,
            height: 300,
            caption: '{title}',
        },
        {
            '@type': 'WebPage',
            '@id': '{canonical}#webpage',
            url: '{canonical}',
            name: '{title}',
            isPartOf: {
                '@id': 'https://www.brewerydb.com/#website',
            },
            primaryImageOfPage: {
                '@id': '{canonical}#primaryimage',
            },
            datePublished: '2011-11-19T19:00:00+00:00',
            dateModified: '2022-10-11T16:22:03+00:00',
            description: '{description}',
            breadcrumb: {
                '@id': '{canonical}#breadcrumb',
            },
            inLanguage: 'en-US',
            potentialAction: [
                {
                    '@type': 'ReadAction',
                    target: ['{canonical}'],
                },
            ],
        },
        {
            '@type': 'Brewery',
            image: ['{image}'],
            name: '{title}',
            address: {
                '@type': 'PostalAddress',
                streetAddress: '{address.street}',
                addressLocality: '{address.city}',
                addressRegion: '{address.state}',
                postalCode: '{address.postalCode}',
                addressCountry: '{address.country}',
            },
            geo: {
                '@type': 'GeoCoordinates',
                latitude: '{address.geo.lat}',
                longitude: '{address.geo.lon}',
            },
            url: '{canonical}',
            telephone: '{contact.phone}',
            // "servesCuisine": "American", // for futue food options
            // "priceRange": "$$$",
            openingHoursSpecification: [
                // @TODO: make a parse function that returns this data from an OperatingHours object
                {
                    '@type': 'OpeningHoursSpecification',
                    dayOfWeek: ['Monday', 'Tuesday'],
                    opens: '11:30',
                    closes: '22:00',
                },
                {
                    '@type': 'OpeningHoursSpecification',
                    dayOfWeek: ['Wednesday', 'Thursday', 'Friday'],
                    opens: '11:30',
                    closes: '23:00',
                },
                {
                    '@type': 'OpeningHoursSpecification',
                    dayOfWeek: 'Saturday',
                    opens: '16:00',
                    closes: '23:00',
                },
                {
                    '@type': 'OpeningHoursSpecification',
                    dayOfWeek: 'Sunday',
                    opens: '16:00',
                    closes: '22:00',
                },
            ],
            menu: '{canonical}#brews',
        },
        {
            ...breadCrumbList,
            '@id': '{canonical}#breadcrumb',
            itemListElement: [
                ...breadCrumbList.itemListElement,
                {
                    '@type': 'ListItem',
                    position: 2,
                    name: '{brand.title}',
                    item: '{brand.path}',
                },
                {
                    '@type': 'ListItem',
                    position: 3,
                    name: '{title}',
                    item: '{canonical}',
                },
            ],
        },
    ],
}
const product = {
    ...global,
    '@graph': [
        ...global['@graph'],
        {
            '@type': 'ImageObject',
            inLanguage: 'en-US',
            '@id': '{image}#primaryimage',
            url: '{image}',
            contentUrl: '{image}',
            width: 300,
            height: 300,
            caption: '{title} - {brand.title}',
        },
        {
            '@type': 'WebPage',
            '@id': '{canonical}#webpage',
            url: '{canonical}',
            name: '{title}',
            isPartOf: {
                '@id': 'https://www.brewerydb.com/#website',
            },
            primaryImageOfPage: {
                '@id': '{canonical}#primaryimage',
            },
            datePublished: '2011-11-19T19:00:00+00:00',
            dateModified: '2022-10-11T16:22:03+00:00',
            description: '{description}',
            breadcrumb: {
                '@id': '{canonical}#breadcrumb',
            },
            inLanguage: 'en-US',
            potentialAction: [
                {
                    '@type': 'ReadAction',
                    target: ['{canonical}'],
                },
            ],
        },
        {
            '@type': 'Product',
            url: '{canonical}',
            name: '{title}',
            image: '{image}',
            sameAs: '{canonical}#webpage',
        },
        {
            ...breadCrumbList,
            '@id': '{canonical}#breadcrumb',
            itemListElement: [
                ...breadCrumbList.itemListElement,
                {
                    '@type': 'ListItem',
                    position: 2,
                    name: '{brand.title}',
                    item: '{brand.path}',
                },
                {
                    '@type': 'ListItem',
                    position: 3,
                    name: '{title}',
                    item: '{canonical}',
                },
            ],
        },
    ],
}
const brand = {
    ...global,
    '@graph': [
        ...global['@graph'],
        {
            '@type': 'ImageObject',
            inLanguage: 'en-US',
            '@id': '{image}#primaryimage',
            url: '{image}',
            contentUrl: '{image}',
            width: 300,
            height: 300,
            caption: '{title}',
        },
        {
            '@type': 'WebPage',
            '@id': '{canonical}#webpage',
            url: '{canonical}',
            name: '{title}',
            isPartOf: {
                '@id': 'https://www.brewerydb.com/#website',
            },
            primaryImageOfPage: {
                '@id': '{canonical}#primaryimage',
            },
            datePublished: '2011-11-19T19:00:00+00:00',
            dateModified: '2022-10-11T16:22:03+00:00',
            description: '{description}',
            breadcrumb: {
                '@id': '{canonical}#breadcrumb',
            },
            inLanguage: 'en-US',
            potentialAction: [
                {
                    '@type': 'ReadAction',
                    target: ['{canonical}'],
                },
            ],
        },
        {
            ...breadCrumbList,
            '@id': '{canonical}#breadcrumb',
            itemListElement: [
                ...breadCrumbList.itemListElement,
                {
                    '@type': 'ListItem',
                    position: 2,
                    name: '{title}',
                    item: '{canonical}',
                },
            ],
        },
    ],
}

const defaultPage = {
    ...global,
}

export { home, download, map, location, brand, product, organization, defaultPage }
